<script setup>
import {computed} from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
	checked: {
		type: [Array, Boolean],
		default: false,
	},
	value: {
		type: String,
		default: null,
	},
});

const proxyChecked = computed({
	get()
	{
		return props.checked;
	},

	set(val)
	{
		emit('update:checked', val);
	},
});
</script>


<template>
	<input
		v-model="proxyChecked"
		:value="value"
		class="rounded dark:bg-dark-900 border-dark-300 dark:border-dark-700 text-primary-600 shadow-sm focus:ring-primary-500 dark:focus:ring-primary-600 dark:focus:ring-offset-dark-800"
		type="checkbox"
	>
</template>
